import React from 'react'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { Link } from 'gatsby'
import Container from './container'
import MenuButton from './menu-button'
import './header.css'

const block = 'header'

/**
 * Displays a header containing a logo a top level navigation
 *
 * @param  {array}  props.links - ...
 * @return {object}             - React markup
 */
const Header = ({
  links = [
    { label: 'projects', value: '/projects' },
    { label: 'work', value: '/work' }
  ]
}) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const {width} = useWindowSize()

  useEffect(() => {
    document.body.style.overflow = menuVisible ? 'hidden' : 'auto'
  })

  return (
    <Container className={ block }>
      <header className={ `${block}__inner` }>
        <h1 className={ `${block}__logo` }>
          <Link
            className={ `${block}__logo-link` }
            to="/"
          >
            <span className={ `${block}__greg` }>
              Greg
            </span>
            <span className={ `${block}__venech` }>
              Venech
            </span>
          </Link>
        </h1>
        { width < 720 ? (
          <MenuButton
            open={ menuVisible }
            onClick={ () => setMenuVisible(!menuVisible) }
          />
        ) : null }
        <nav
          className={ `${block}__nav` }
          style={ width < 720 && !menuVisible ? {
            opacity: 0,
            pointerEvents: 'none'
          } : {}}>
          { links.map(link => (
            <Link
              key={ link.value }
              className={ `${block}__link` }
              activeClassName={ `${block}__link--active` }
              to={ link.value }
              partiallyActive={true}
              onClick={ () => setMenuVisible(false) }
            >
              { link.label }
            </Link>
          ))}
        </nav>
      </header>
    </Container>
  )
}

Header.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object)
}

export default Header