import React from 'react'
import PropTypes from 'prop-types'
import './container.css'

const block = 'container'

/**
 * Contains and centers any type of content
 * 
 * @param  {object} props.children - Any valid node or nodes
 * @return {object}                - React markup
 */
const Container = ({
	className = '',
	style = {},
	children
}) => (
	<div
		className={ `${block} ${className}` }
		style={ style }
	>
		{ children }
	</div>
)

Container.propTypes = {
	style: PropTypes.object,
	children: PropTypes.node
}

export default Container