import React from 'react'
import PropTypes from 'prop-types'
import './line.css'

const block = 'line'


const Line = ({
	position = 'bottom-left',
	orientation = 'horizontal',
	length = 50,
	offset = 5
}) => {
	const x = position.split('-')[1]
	const y = position.split('-')[0]

	return (
		<figure
			className={ block }
			style={{
				[y]: -offset,
				[x]: -offset,
				width: orientation === 'horizontal' ? length : 1,
				height: orientation === 'vertical' ? length : 1
			}}
		/>
	)
}

Line.propTypes = {
	position: PropTypes.oneOf([ 'top-left', 'top-right', 'bottom-right', 'bottom-left' ]),
	orientation: PropTypes.oneOf([ 'horizontal', 'vertical' ]),
	length: PropTypes.number
}

export default Line