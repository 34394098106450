import React from 'react'
import PropTypes from 'prop-types'
import Header from './header'
import Container from './container'
import Footer from './footer'
import 'reset-css'
import './layout.css'

const block = 'layout'

/**
 * The main layout for every page
 * 
 * @param  {string} props.className - ...
 * @param  {object} props.children  - ...
 * @return {object}                 - React markup
 */
const Layout = ({
  className = '',
  children
}) => (
  <div className={ `${block} ${className}` }>
    <Header />
    <Container className={ `${block}__content` }>
      <main>
        { children }
      </main>
    </Container>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout