import React from 'react'
import PropTypes from 'prop-types'
import './menu-button.css'

const block = 'menu-button'

/**
 * Displays an animated menu button
 * 
 * @param  {boolean} props.open - Indicates whether the menu is open or closed
 * @return {object}             - React markup
 */
const MenuButton = ({
    open = false,
    ...props
}) => {
  return (
    <figure
      { ...props }
      className={ block + (open ? ` ${block}--open` : '') }
    >
      <span className={ `${block}__line` } />
      <span className={ `${block}__line` } />
      <span className={ `${block}__line` } />
    </figure>
  )
}

MenuButton.propTypes = {
	open: PropTypes.bool
}

export default MenuButton