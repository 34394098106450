import React from 'react'
import PropTypes from 'prop-types'
import Layout from './layout'
import Line from './line'
import SEO from '../components/seo'
import 'github-markdown-css'
import './page.css'

const block = 'page'


const Page = ({
  pageContext: {
    frontmatter
  },
  children
}) => (
  <Layout className={ block }>
    <SEO title={frontmatter.title} />
    { frontmatter.image ? (
      <figure
        className={ `${block}__image` }
        style={{
          backgroundImage: `url(${require(`../images/${frontmatter.image}`)})`
        }}
      >
        <Line
          position={ `top-left` }
          offset={ 8 }
          length={ 100 }
        />
        <Line
          position={ `top-left` }
          orientation="vertical"
          offset={ 8 }
        />
        <Line
          position={ `bottom-right` }
          offset={ 8 }
          length={ 100 }
        />
        <Line
          position={ `bottom-right` }
          orientation="vertical"
          offset={ 8 }
        />
      </figure>
    ) : null }
    <h1
      className={ `${block}__title` }
      style={{ marginTop: frontmatter.image ? 24 : 0 }}>
      { frontmatter.title }
      <Line />
    </h1>
    <div className={ `${block}__content markdown-body` }>
      { children }
    </div>
  </Layout>
)

Page.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
}

export default Page