import React from 'react'
import PropTypes from 'prop-types'
import { useWindowSize } from 'react-use'
import Container from './container'
import Line from './line'
import './footer.css'

const block = 'footer'

/**
 * Displays a footer containing a copyright notice and various links
 * 
 * @param  {array}  props.links - ...
 * @return {object}   			- React markup
 */
const Footer = ({
	links = [
		{ label: 'contact', value: 'mailto:greg.venech@gmail.com' },
		{ label: 'github', value: 'https://github.com/skipjack' },
		{ label: 'dribbble', value: 'https://dribbble.com/skipjack' },
		{ label: 'stack overflow', value: 'https://stackoverflow.com/users/3297971/skip-jack' }
	]
}) => {
	const { width } = useWindowSize()

	return (
    <Container className={ block }>
      <div className={ `${block}__left` }>
        <span className={ `${block}__copyright` }>
          © Greg Venech { new Date().getFullYear() }
          <Line
            position="bottom-right"
            orientation={ width < 720 ? 'horizontal' : 'vertical' }
            offset={ 10 }
            length={ 24 } />
        </span>
      </div>
      <nav className={ `${block}__nav` }>
        { links.map((link, i) => (
          <a
            key={ link.label }
            className={ `${block}__link` }
            target="_blank"
            rel="noopener noreferrer"
            href={ link.value }
          >
            { link.label }
            { i + 1 < links.length ? (
              <Line
                position={ 
                  width < 720 ? 
                  (i % 2 ? 'bottom-right' : 'bottom-left') :
                  (i % 2 ? 'bottom-right' : 'top-right')
                }
                orientation={ width < 720 ? 'horizontal' : 'vertical' }
                offset={ 10 }
                length={ 24 } />
            ) : null }
          </a>
        ))}
      </nav>
    </Container>
	)
}

Footer.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string
		})
	)
}

export default Footer